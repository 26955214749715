import React from "react";

import { Layout, SEO } from "../components";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <h1>Not found, sorry!</h1>
    </Layout>
  );
};

export default NotFoundPage;
